import axios from '@axios'
import useJwt from '@core/auth/jwt/useJwt'

const { jwt } = useJwt(axios, {})
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchHolidays(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/avenir_holiday', { })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchElements(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/availability', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchData(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/availability/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDataForExcel(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/availabilityForExcel`, { params: { id: id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData ) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/cases/availability', userData, {
            headers: {
                'Content-Type': 'application/json',
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editUser(ctx, { id, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/cases/availability/${id}`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchJobs(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/job', { params: { perPage: 50, page: 1 } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchIdTypes(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/document_type', { params: { perPage: 50, page: 1 } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchImage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/core/image/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDepartments(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', { 
            params: { deep: 2 } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCities(ctx, {parent}) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', { 
            params: { deep: 3, parent: parent } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePassword(ctx, { id, userPassword }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/user/${id}/password`, userPassword)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
