<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
    </b-alert>

    <template v-if="userData">
      <b-card>
        <b-row>
          <!-- Right Col: Table -->
          <b-col
            cols="12"
            xl="6"
          >
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <feather-icon
                    class="mr-75"
                    icon="BookOpenIcon"
                  />
                  <span class="font-weight-bold">{{ $t('user.firtName') }}</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ userData.firt_name }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    class="mr-75"
                    icon="BookOpenIcon"
                  />
                  <span class="font-weight-bold">{{ $t('user.middleName') }}</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ userData.middle_name }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    class="mr-75"
                    icon="BookOpenIcon"
                  />
                  <span class="font-weight-bold">{{ $t('user.surname1') }}</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ userData.surname_1 }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    class="mr-75"
                    icon="BookOpenIcon"
                  />
                  <span class="font-weight-bold">{{ $t('user.surname2') }}</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ userData.surname_2 }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    class="mr-75"
                    icon="MailIcon"
                  />
                  <span class="font-weight-bold">{{ $t('user.email') }}</span>
                </th>
                <td class="pb-50">
                  {{ userData.email }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    class="mr-75"
                    icon="BriefcaseIcon"
                  />
                  <span class="font-weight-bold">{{ $t('user.jobTitle') }}</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ getTypeValue(userData, 'job_title') }}
                </td>
              </tr>
              <tr>
                <th>
                  <feather-icon
                    class="mr-75"
                    icon="PhoneIcon"
                  />
                  <span class="font-weight-bold">{{ $t('user.workCellPhone') }}</span>
                </th>
                <td>
                  {{ userData.work_cell_phone }}
                </td>
              </tr>
              <tr>
                <th>
                  <feather-icon
                    class="mr-75"
                    icon="BookOpenIcon"
                  />
                  <span class="font-weight-bold">{{ $t('user.medicalRecord') }}</span>
                </th>
                <td>
                  {{ userData.medical_record }}
                </td>
              </tr>
              <tr>
                <th>
                  <feather-icon
                    class="mr-75"
                    icon="BookOpenIcon"
                  />
                  <span class="font-weight-bold">{{ $t('user.occupationalMedicalLicense') }}</span>
                </th>
                <td>
                  {{ userData.occupational_medical_license }}
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
        <hr>
        <!-- Action Buttons -->
        <b-row>
          <b-col>
            <router-link :to="{name: 'apps-availabilities-list'}">
              <b-button
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              >
                {{ $t('actions.back') }}
              </b-button>
            </router-link>
          </b-col>
        </b-row>
      </b-card>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BAlert, BLink, BBadge, BButton, BCard,
} from 'bootstrap-vue'
import UserStoreModule from '../storeModule'
import UserInfoCard from './InfoCard.vue'

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BRow,
    BCol,
    BAlert,
    BLink,
    // Local Components
    UserInfoCard,
  },
  data() {
    return {
      userData: undefined,
      USER_APP_STORE_MODULE_NAME: 'app-user',
    }
  },
  mounted() {
    // Register module
    if (!store.hasModule(this.USER_APP_STORE_MODULE_NAME)) store.registerModule(this.USER_APP_STORE_MODULE_NAME, UserStoreModule)

    this.fetchUser()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.USER_APP_STORE_MODULE_NAME)) store.unregisterModule(this.USER_APP_STORE_MODULE_NAME)
  },
  methods: {
    fetchUser() {
      store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
        .then(response => {
          this.userData = response.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.info('404', this.userData)
            this.userData = undefined
          }
        })
    },
  },
  setup() {
    return {
      getTypeValue(user, type) {
        return user[type] ? user[type].value : ''
      },
    }
  },
}
</script>

<style>

</style>
